import AxiosService from "@/services/AxiosService";

class StatisticsService {
  async getStatistics(payload) {
    return await AxiosService.get(`/statistics`, { params: payload })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getBrowsers() {
    return await AxiosService.get(`/browsers`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getCountries() {
    return await AxiosService.get(`/countries`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getOs() {
    return await AxiosService.get(`/os`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getSubAccs() {
    return await AxiosService.get(`/user-subaccs`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async downloadCSV(payload) {
    return await AxiosService.get("/download-csv-statistics", {
      params: payload,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getStatisticsChartData(payload) {
    return await AxiosService.get("/statistics-chart", { params: payload })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new StatisticsService();

<template>
  <custom-multiselect
    v-model="language"
    :list="languageSelect"
    :class="{ 'select-language': leftMenu }"
    :allowEmpty="false"
    @input="changeLanguage"
  >
    <template v-slot:block="props">
      {{ props.value.toUpperCase() }}
    </template>
    <template v-slot:list="props">
      {{ props.value.toUpperCase() }}
    </template>
  </custom-multiselect>
</template>
<script>
import ServicePanel from "@/api/servicePanel";
import { mapGetters } from "vuex";

export default {
  name: "selectLanguage",
  props: {
    leftMenu: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      language: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en",
      languageSelect: ["ru", "en", "uk", "es", "pt"],
      isLoading: false,
    };
  },
  beforeCreate() {
    const savedLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    if (savedLang) {
      this.$i18n.locale = savedLang;
      this.language = savedLang;
    } else {
      this.$i18n.locale = navigator.language || navigator.userLanguage;
    }
    this.isLoading = true;
  },
  mounted() {
    this.isLoading = false;
  },
  watch: {
    "$i18n.locale": function () {
      this.language = this.$i18n.locale;
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    changeLanguage(event) {
      const newLang = event;
      localStorage.setItem("lang", newLang);
      this.$i18n.locale = newLang;
      const langPayload = {
        language: newLang === "ru" ? "ru" : "en",
      };
      if (this.user && this.user.id) {
        ServicePanel.updateLanguage(langPayload);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.multiselect {
  &.select-language {
    width: 100%;
  }
}
</style>

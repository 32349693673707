import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Login",
      pageTitle: "authorisation",
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("@/views/auth/Registration.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Registration",
      pageTitle: "sign_up",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPass",
    component: () => import("@/views/auth/ResetPass.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Reset Password",
      pageTitle: "change_pass",
    },
  },

  {
    path: "/recovery-password",
    name: "RecoveryPassword",
    component: () => import("@/views/auth/RecoveryPass.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Recovery Password",
      pageTitle: "",
    },
  },
  {
    path: "/terms-of-use",
    name: "Terms",
    component: () => import("@/views/TermsOfUse"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/views/Privacy"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/",
    name: "Landing",
    component: () => import("@/views/Landing"),
    meta: {
      auth: false,
    },
    beforeEnter: (to,from,next) => {
      localStorage.removeItem("isDarkTheme");
      next()
    }
  },
  {
    path: "/id:id(\\w+)",
    name: "LandingRef",
    component: () => import("@/views/Landing"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/extra-monetization/pid/:id",
    name: "ExtraMonetizationPid",
    component: () => import("@/views/ExtraMonetization"),
    meta: {
      layout: "default-layout",
    },
    beforeEnter: (to, from, next) => {
      const layout = localStorage.getItem("authToken")
        ? "panel-layout"
        : "default-layout";
      to.meta.layout = layout;

      next();
    },
  },
  {
    path: "/extra-monetization/",
    name: "ExtraMonetization",
    component: () => import("@/views/ExtraMonetization"),
    meta: {
      layout: "default-layout",
    },
    beforeEnter: (to, from, next) => {
      const layout = localStorage.getItem("authToken")
        ? "panel-layout"
        : "default-layout";
      to.meta.layout = layout;

      next();
    },
  },
  {
    path: "/default/index/pid/:id",
    name: "LandingPid",
    component: () => import("@/views/Landing"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/confirm-wallet",
    name: "ConfirmWallet",
    component: () =>
      localStorage.getItem("authToken")
        ? import("@/views/Dashboard.vue")
        : import("@/views/Landing"),
    meta: {
      layout: localStorage.getItem("authToken")
        ? "panel-layout"
        : "default-layout",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/top",
    name: "Top",
    component: () => import("@/views/Top"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  // don't delete commit, DST-1867
  // {
  //   path: "/cpl-rates",
  //   name: "CPL Rates",
  //   component: () => import("@/views/RatesCPL"),
  //   meta: {
  //     auth: true,
  //     layout: "panel-layout",
  //   },
  // },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/platform-settings/:id",
    name: "PlatformSettings",
    component: () => import("@/views/PlatformSettings"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/platforms",
    name: "Platforms",
    component: () => import("@/views/Platforms"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/support",
    name: "Support",
    component: () => import("@/views/Support"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/support-chat/:id",
    name: "SupportChat",
    component: () => import("@/views/SupportChat"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/create-platform",
    name: "CreatePlatform",
    component: () => import("@/views/CreatePlatform"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/create-platform/arbitrage",
    name: "Arbitrage",
    component: () => import("@/views/createPlatform/Arbitrage.vue"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/edit-platform/:id/arbitrage",
    name: "EditArbitrage",
    component: () => import("@/views/createPlatform/Arbitrage.vue"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/create-platform/site/:step",
    name: "Site",
    component: () => import("@/views/createPlatform/Site.vue"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/edit-platform/:id/site",
    name: "EditSite",
    component: () => import("@/views/createPlatform/Site.vue"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/create-platform/landing/:step",
    name: "Land",
    component: () => import("@/views/createPlatform/Site.vue"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/edit-platform/:id/landing",
    name: "EditLand",
    component: () => import("@/views/createPlatform/Site.vue"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/statistic",
    name: "Statistic",
    component: () => import("@/views/Statistic"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/domain",
    name: "Domain",
    component: () => import("@/views/Domain"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/ref-system",
    name: "RefSystem",
    component: () => import("@/views/RefSystem"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/finances",
    name: "Finances",
    component: () => import("@/views/Finances"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/api-keys",
    name: "ApiKeys",
    component: () => import("@/views/ApiKeys"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () => import("@/views/ErrorPage"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

if (!window.dataLayer) {
  window.dataLayer = [];
}

router.beforeEach((to, from, next) => {

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: to.path,
        name: to.name,
        title: document.title,
      },
    });
  }

  Vue.nextTick(() => {
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute(
        "href",
        `${window.location.protocol}//${window.location.host}${to.path}`
      );
  });

  const isAuthorized = localStorage.getItem("authToken");
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (to.meta.auth === true && !isAuthorized) {
    localStorage.removeItem("isDarkTheme");
    next("/login");
  }
  if (to.path === "/auth") {
    next("/login");
  }
  if (to.meta.auth === false && isAuthorized) {
    next("/dashboard");
  }
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) {
    return next();
  }

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));
});

export default router;

<template>
  <div>
    <div class="single-user">
      <div class="user">
        <div class="user__prev" :class="{ left: left }">
          <span class="user__avatar align-center">{{ avatarName }}</span>
          <span class="user__info" :class="{ full: fullName }">
            <div class="profile d-flex">
            <span class="user__nick">{{ user.name }} </span>
            <svg
              v-if="!fullName"
              v-svg
              symbol="arrow"
              class="mr-2 arrow-left"
              size="0 0 17 17"
              role="info"
              />
              </div>
            <span v-if="!fullName" class="user__id">ID {{ user.id }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.profile {
  width: fit-content;
}
.user {
  max-width: 195px;
  display: flex;
  justify-content: left;
  &__avatar {
    line-height: 1;
  }
  &__info {
    width: calc(100% - 60px);
    &.full {
      width: 100%;
      .user__nick {
        max-width: 100%;
      }
    }
  }
  &__nick {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    max-width: 72px;
    @media screen and (min-width:1400px) {
      max-width: 120px;
    }
  }
  &__id {
    font-size: 14px;
  }
}

svg.arrow-left {
    margin-left: 6px;
    margin-top: 2px;
    background: rgb(0, 0, 0);
    border-radius: 50px;
    padding: 5px;
    fill: rgb(255, 255, 255);
}

@media screen and (max-width: 1300px) {
  .user {
    width: auto;
    &__prev {
      justify-content: end;
      &.left {
        justify-content: start;
      }
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    fullName: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    avatarName() {
      return this.user.name
        ?.split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
  },
};
</script>
